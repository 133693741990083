import html5 from "../assets/images/html5.png";
import css from "../assets/images/css.png";
import javascript from "../assets/images/javascript.png";
import nodejs from "../assets/images/nodejs.png";
import react from "../assets/images/react.png";
import php from "../assets/images/php.png";
import laravel from "../assets/images/laravel.png";
import python from "../assets/images/python.png";
import django from "../assets/images/django.png";
import mariadb from "../assets/images/mariadb.png";

function Main() {
  return (
    <div className="wrap">
      <section className="first">
        <h1>성실함과 책임감을 최우선으로 생각하겠습니다.</h1>
        <h1>
          개발자 <span className="yellow">백성곤</span>입니다.
        </h1>
      </section>
      <section className="about_me">
        <h1 className="top_logo">
          <span className="imogi">😎</span> About Me
        </h1>
        <p>
          다수의 프로그래밍 언어와 프레임워크를 활용 가능한 풀스택을 지향하고
          있는 개발자입니다.
        </p>
        <p>
          새로운 기술 습득에 거부감이 없으며 원만한 의사소통 능력으로 협업할 수
          있습니다.
        </p>
        <p>맡은 바 임무에 성실하고 꼼꼼하게 최선을 다 할 수 있습니다.</p>
      </section>

      <div className="col-12 divide_bar"></div>

      <div className="col-12">
        <section className="profile col-6">
          <h1 className="top_logo">
            <span className="imogi">👨‍💻</span> Profile
          </h1>
          <ul className="disc_list">
            <li>1995년 3월 27일 출생</li>
            <li>한신대학교 컴퓨터공학부 졸업(2014.03-2020.02)</li>
          </ul>
        </section>
        <section className="contact_me col-6">
          <h1 className="top_logo">
            <span className="imogi">📩</span> Contact Me
          </h1>
          <ul className="disc_list">
            <li>Email | canoa0327@naver.com</li>
            <li>Phone | 010-8814-0575</li>
            <li>
              <a href="https://github.com/canoa0327" target="_blank">
                Github
              </a>
            </li>
          </ul>
        </section>
      </div>

      <div className="col-12 mt70">
        <section className="license col-6">
          <h1 className="top_logo">
            <span className="imogi">💡</span> License
          </h1>
          <ul className="disc_list">
            <li>정보처리기능사(2013.01.31)</li>
            <li>정보처리기사(2021.08.20)</li>
          </ul>
        </section>
        <section className="my_skills col-6">
          <h1 className="top_logo">
            <span className="imogi">💪</span> Skills
          </h1>
          <ul className="disc_list skills">
            <div>
              <img src={html5} />
              <img src={css} />
              <img src={javascript} />
              <img src={nodejs} />
            </div>
            <div>
              <img src={react} />
              <img src={php} />
              <img src={laravel} />
              <img src={mariadb} />
            </div>
            <div>
              <img src={python} />
              <img src={django} />
            </div>
          </ul>
        </section>
      </div>
      <div className="col-12 divide_bar"></div>
      <section className="projects">
        <h1 className="top_logo">
          <span className="imogi">⌨️</span> Projects
        </h1>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2021.07 - 2022.10</h3>
            <div className="project_content_box">
              <p className="project_title">탁구경기 자동심판 "자심"</p>
              <p className="project_skills">#PHP #Jquery #MariaDB</p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>탁구 경기에서 결과를 자동으로 판정해주는 심판로봇</li>
                  <li>심판로봇이 웹서버로 보내는 API 처리</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>
                    카카오톡, 네이버, 페이스북, 구글 4플랫폼 소셜 로그인 구현
                  </li>
                  <li>득점 발생 시 점수판이 바뀌는 실시간 경기 중계 기능</li>
                  <li>경기 종료 후 선수별 득점내역 및 결과보기</li>
                  <li>
                    승/패 여부, 레이팅 점수 차이에 따라 변동되는 레이팅 시스템
                    구현
                  </li>
                  <li>커뮤니티 게시판 구현</li>
                  <li>모바일 전용 페이지 구현</li>
                </ul>
                <h5 className="project_subnum">3. 결과 및 기여한 점</h5>
                <ul>
                  <li>기획, UI 설계를 직접 진행했던 기회 경험</li>
                  <li>웹개발의 프로세스 파악에 도움</li>
                </ul>
                <h5 className="project_hyperlink">
                  <a href="https://joypingpong.com" target="_blank">
                    서비스 바로가기
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 divide_thick_bar"></div>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2022.10 - 2022.11</h3>
            <div className="project_content_box">
              <p className="project_title">백두세라믹 홈페이지</p>
              <p className="project_skills">
                #PHP #jQuery #React #NestJS #MariaDB
              </p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>아버지께서 운영중인 회사 소개 홈페이지</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>화면 구성, 디자인, 퍼블리싱 100% 진행</li>
                  <li>관리자 CRUD 시스템 설계 및 구현</li>
                  <li>모바일 전용 페이지 별도 제작</li>
                </ul>
                <h5 className="project_subnum">3. 결과 및 기여한 점</h5>
                <ul>
                  <li>기획 - 개발 - 배포 모든 프로세스를 진행</li>
                  <li>기존 영업에 사용했던 종이 소재의 카탈로그 불필요화</li>
                  <li>웹서비스 개시로 인한 신규 고객 유입 및 확보</li>
                  <li>현재 React & Nestjs로 마이그레이션 진행 중</li>
                </ul>
                <h5 className="project_hyperlink">
                  <a href="http://백두세라믹.com" target="_blank">
                    서비스 바로가기
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 divide_thick_bar"></div>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2022.11 - 2023-06</h3>
            <div className="project_content_box">
              <p className="project_title">네트워크 마케팅 정산 솔루션</p>
              <p className="project_skills">#PHP #jQuery #MariaDB #GNU5</p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>네트워크 마케팅 정산을 위한 통합솔루션</li>
                  <li>스테이킹 이자 지급을 비롯한 관리자(Admin) 기능</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>풀스택 개발</li>
                  <li>추천인 시스템 구현</li>
                  <li>구매한 마케팅 상품에 대한 이자 지급 알고리즘 구현</li>
                  <li>crontab을 이용한 스케줄러 관리와 자동화 설정</li>
                  <li>관리자 권한의 기능 구현</li>
                </ul>
                <h5 className="project_subnum">3. 결과 및 기여한 점</h5>
                <ul>
                  <li>시스템 자동화로 인한 고객사 만족도 증가</li>
                  <li>유지보수 문의에 정확한 대응 중</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 divide_thick_bar"></div>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2022.11 - 2023-06</h3>
            <div className="project_content_box">
              <p className="project_title">코인 스테이킹 상품 관리 솔루션</p>
              <p className="project_skills">#PHP #jQuery #MariaDB #GNU5</p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>스테이킹 이자 정산을 위한 통합솔루션</li>
                  <li>스테이킹 이자 지급을 비롯한 관리자(Admin) 기능</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>풀스택 개발</li>
                  <li>
                    코인 입/출금 시 실시간 잔고반영 처리와 스테이킹 상품
                    구매처리
                  </li>
                  <li>구매한 스테이킹 상품에 대한 이자 지급 알고리즘 구현</li>
                  <li>crontab을 이용한 스케줄러 관리와 자동화 설정</li>
                  <li>관리자 권한의 기능 구현</li>
                </ul>
                <h5 className="project_subnum">3. 결과 및 기여한 점</h5>
                <ul>
                  <li>시스템 자동화로 인한 고객사 만족도 증가</li>
                  <li>유지보수 문의에 정확한 대응 중</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 divide_thick_bar"></div>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2023.12 - 2023-12</h3>
            <div className="project_content_box">
              <p className="project_title">ESG GLOBAL VINA</p>
              <p className="project_skills">#HTML #CSS #Javascript</p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>베트남 케나프 농장 소개 홈페이지</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>반응형으로 제작</li>
                  <li>한국어, 영어, 베트남어 지원</li>
                  <li>문의하기에 메일 전송 기능</li>
                </ul>

                <h5 className="project_hyperlink">
                  <a href="https://esgglobalvina.com/" target="_blank">
                    서비스 바로가기
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 divide_thick_bar"></div>
        <div className="project_wrap">
          <div className="project_inner">
            <h3 className="project_period">2023.08 - 진행중</h3>
            <div className="project_content_box">
              <p className="project_title">
                환경보호를 위한 모두의 이야기 GREEN TALK
              </p>
              <p className="project_skills">
                #React #Recoil #Node.js #Express.js #MariaDB
              </p>
              <div className="project_contents">
                <h5 className="project_subnum">1. 프로젝트 간략 소개</h5>
                <ul>
                  <li>
                    환경보호 활동을 기반으로한 챌린지 및 프로모션 참여 시 포인트
                    지급
                  </li>
                  <li>지급받은 포인트로 친환경 제품 구매(그린톡 몰)</li>
                </ul>
                <h5 className="project_subnum">2. 구현 내용</h5>
                <ul>
                  <li>Recoil 라이브러리를 활용한 전역 상태관리</li>
                  <li>JWT 기반의 회원/관리자 구분 인증 미들웨어 설계</li>
                  <li>포인트 지급 시스템 및 예외처리 설계, 구현</li>
                  <li>퍼스트몰 입점형 솔루션을 커스텀하여 그린톡과 연결</li>
                  <li>
                    그린톡과 그린톡 몰의 각 데이터를 동일하게 유지해주는 주기적
                    동기화 스케줄러 구현
                  </li>

                  <li>관리자 페이지 기능 구현</li>
                </ul>
                <h5 className="project_subnum">3. 결과 및 기여한 점</h5>
                <ul>
                  <li>프론트 30%, 백엔드 80% 정도의 기여도 달성</li>
                  <li>AWS EC2와 RDS 환경에서 개발 경험</li>
                  <li>
                    탄탄한 기초공사를 기반으로 한 프로젝트 설계의 중요도를
                    느끼게 됨
                  </li>
                </ul>
                <h5 className="project_hyperlink">
                  <a href="https://greentalk.kr" target="_blank">
                    서비스 바로가기
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="col-12 divide_bar"></div>
      <section className="other_exp">
        <h1 className="top_logo">
          <span className="imogi">⌨️</span> 그 외의 경험
        </h1>
        <div className="other_wrap">
          <div className="other_inner">
            <ul className="disc_list">
              <li>클라우드 환경에서 웹서버 설치 및 설정, SSL 인증서 적용</li>
              <li>클라우드플레어를 활용한 DNS 설정</li>
              <li>
                Python Django를 이용한 Custom Model(AbstractBaseUser) 설계
              </li>
              <li>Spring Boot를 이용한 CRUD 학습</li>
              <li>슬로우 쿼리를 개선할 수 있는 쿼리 튜닝</li>
              <li>PHP 모던 프레임워크인 Laravel 사용 경험</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Main;
