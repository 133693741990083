import "./assets/styles/App.css";
import Main from "./components/Main.js";

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
